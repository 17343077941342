import { useNavigate } from 'react-router-dom';
import Profile from '../../../assets/member/member1.png'
import { MdExitToApp } from "react-icons/md";
const AdminHeader = () => {
  const navigate = useNavigate();
  
  const handleLogOut = ()=>{
    navigate('/login')
  }
  return (
    <div className='bg-[#754C28] text-white font font-poppins flex justify-between items-center h-auto pl-[20px] pr-[100px] shadow-md'>
      <div className='flex items-center pt-[8px] gap-[10px]'>  
        <p>KAGOSIDA</p>
         </div>

         <div>
         <div className='flex w-full items-center   gap-[10px] ' >
          <img className='md:w-[8%] w-[8%] rounded-[50%] border-1  bg-red-500 ' src={Profile} alt="" />
           <p className='pt-[10px]'>Admin Kagosida </p>
           <div className='relative'>
           <button onClick={handleLogOut} ><MdExitToApp className='text-[23px]'  /></button>
          
           </div>
           
           
      </div>

    
         </div>
    </div>
  )
}

export default AdminHeader