import React from 'react'
import { NavLink } from 'react-router-dom'

const MemberListRoute = () => {
  return (
    <div>
      <nav className='ml-[25px]'>
        <NavLink  to="memberList"  className="no-underline text-black">Members List</NavLink>
      </nav>
    </div>
  )
}

export default MemberListRoute