import React from 'react'
import GmsTola from '../../../components/homeComponents/GmsTola'
import RecentNews from '../../../shared/RecentNews'
import GoToTop from '../../../Scroll/GoToTop'
import Chatbot from '../../../shared/ChatBot'

const News = () => {
  return (
    <div>
      <GmsTola />
      <Chatbot />
      <RecentNews />
      <GoToTop />
    </div>
  )  
}

export default News