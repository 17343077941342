import React from 'react';
import { NavLink } from 'react-router-dom';

const AboutUSRoute = () => {
  return (
    <div className='pl-[10px]'>
      <nav className='grid grid-cols-1 gap-2'>
        <NavLink 
          to="past-committee" 
          className="text-black hover:text-blue-500 no-underline"
        >
          Past Committee 
        </NavLink>
        <NavLink 
          to="executive-committee" 
          className="text-black hover:text-blue-500 no-underline"
        >
          Executive Committee
        </NavLink>
      </nav>
    </div>
  );
}

export default AboutUSRoute;
