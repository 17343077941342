import React from 'react'
import Gallery from '../../../shared/Gallery'
import GmsTola from '../../../components/homeComponents/GmsTola'
import GoToTop from '../../../Scroll/GoToTop'
import Chatbot from '../../../shared/ChatBot'
const PhotoGallery = () => {
  return (
    <div>
      <GmsTola />
     <Gallery />
     <GoToTop />
     <Chatbot />
    </div>
  )
}

export default PhotoGallery