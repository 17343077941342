import React from 'react'
import GmsTola from '../../../components/homeComponents/GmsTola'
import RecentNews from '../../../shared/Gallery'
import GoToTop from '../../../Scroll/GoToTop'
import Chatbot from '../../../shared/ChatBot'

const Introduction = () => {
  return (
    <>
    <GmsTola />
    <Chatbot />
    <div className='mx-[20px] md:mx-[10px] font-poppins mt-[20px] mb-[30px] '>
      <h1 className=' border-b-[1px] border-blue-gray-300 mb-[15px]'>Introduction</h1>
     <p className='text-start'>
     नेपाल सुनचाँदी व्यवसायी महासंघ, मुलुकभरका सुनचाँदी पेसा व्यवसायमा आबद्ध व्यवसायीहरूको साझा संस्था हो । यसले सुनचाँदी क्षेत्रमा आबद्ध व्यवसायीको हकअधिकारको सुनिश्चितता तथा सुनचाँदीका गरगहनाको खरिदबिक्री एवं कारोबारमा देखा परेका समस्यालाई समाधान गर्ने प्रमुख उद्देश्य लिएको छ । 
     नेपाल सुनचाँदी व्यवसायी महासंघ, मुलुकभरका सुनचाँदीको पेसा व्यवसायमा आबद्ध व्यवसायीहरूको साझा संस्था हो । सुनचाँदी क्षेत्रमा आबद्ध व्यवसायीको हक अधिकारको सुनिश्चितता तथा सुनचाँदीका गरगहनाको खरिदबिक्री एवं कारोबारमा देखा परेका समस्यालाई समाधान गर्ने यसको प्रमुख उद्देश्य रहेको छ । सुनचाँदी व्यवसायी सँघ हुँदै महासंघमा परिणत भए पश्चात हामीले अहिले सुनचाँदी व्यवसायी महासंघको सातौं तथा आठौं वार्षिक साधारणसभा तथा तेस्रो महाअधिवेशन सम्पन्न गरी नयाँ नेतृत्वकासाथ कार्यारम्भ गरेका छौं । व्यवसायिक सुरक्षा  र यसको सुनिश्चितता नै आजको प्रमुख आवश्यकता हो । 
     </p>

     <p className='text-start'>
     सुनचाँदीको ब्यवसायमा सरकार, उपभोक्ता, ब्यवसायी र कालिगढको चौतर्फि सम्वन्ध रहेको हुन्छ । उपभोक्तालाई सुरक्षित र गुणस्तरीय सेवाको उपलब्धता, ब्यवसायीलाई ब्यवसायिक सुरक्षा एवं अधिकार र प्रतिफल, कालिगढलाई उपयुक्त रोजगारी र आयको ब्यवस्था तथा सरकारलाई राजस्व अभिवृद्धि र अर्थतन्त्रमा टेवा पुग्ने काम यो ब्यवसायवाट भैरहेको छ । मेरो नेतृत्वमा रहेको हाम्रो यस महासंघले यही मूल मर्मलाई आत्मसात गर्दै अगाडि बढ्ने प्रण हामी गर्दछौं । यसका लागि महासंघको नेतृत्व सदैव कृयाशील रहने बिश्वास दिलाउन चाहन्छु । समयको दौरानमा आउने आरोह अवरोहहरूलाई हामीले सामुहिकरूपमा सम्वोधन गर्दै आफ्नो पेशागत सुरक्षा र अधिकारका लागि सक्दो पहल जारी राख्दै आएका छौं  र आगमी दिनमा पनि त्यसलाई निरन्तरता दिने प्रण गर्दछौं ।
     </p>
    </div>
     <RecentNews />
     <GoToTop />
    </>
  )
}

export default Introduction