import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import footer from "../assets/images/footer.png";
import axios from "axios";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
const Footer = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Perform the data fetching operation
    axios
      .get(`${apiUrl}/api/v1/getnews`)
      .then((response) => {
        const fetchedData = response.data.data || [];

        // Sort the data in descending order based on date
        const sortedData = fetchedData.sort((a, b) => new Date(b.date) - new Date(a.date));
        setData(sortedData); // Update the state with sorted data
    
      })
      .catch((err) => {
        // Handle errors
        console.log(err); // For debugging purposes
      });
  }, []);

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return {
      date: date.toLocaleDateString(),
    };
  };

  return (
    <>
      {/* */}
      <div className="  bg-[#754C28] grid grid-cols-3 p-[10px] gap-[20%] md:gap-0  md:grid-cols-1 h-auto md:py-[20px] font-poppins">
        <div className="py-[20px]">
          <h5 className="font-semibold text-white">Quick Links</h5>
          <nav className="grid grid-cols-1 text-white space-y-[10px] ">
            <NavLink
              to="/executive"
              className="no-underline text-white border-b-[1px] border-[#3e3d3c]  hover:text-[18px] hover:duration-1000 duration-1000"
            >
              Executive Committee{" "}
            </NavLink>
            <NavLink className="no-underline text-white border-b-[1px] border-[#3e3d3c] hover:text-[18px] hover:duration-1000 duration-1000">
              Rate History
            </NavLink>
            <NavLink
              to="/graph"
              className="no-underline text-white border-b-[1px] border-[#3e3d3c] hover:text-[18px] hover:duration-1000 duration-1000"
            >
              Rate Graph{" "}
            </NavLink>
            <NavLink
              to="/news"
              className="no-underline v text-white border-b-[1px] border-[#3e3d3c] hover:text-[18px] hover:duration-1000 duration-1000"
            >
              Latest News{" "}
            </NavLink>
            <NavLink
              to="/notices"
              className="no-underline text-white border-b-[1px] border-[#3e3d3c] hover:text-[18px] hover:duration-1000 duration-1000"
            >
              Notices
            </NavLink>
            <NavLink className="no-underline text-white border-b-[1px] border-[#3e3d3c] hover:text-[18px] hover:duration-1000 duration-1000">
              Press Released
            </NavLink>
          </nav>
        </div>
        <div>
          <div className="py-[20px]">
            <h5 className="font-semibold text-white">Weekly news</h5>
            <nav className="grid grid-cols-1 text-white space-y-[12px] ">
              {data.slice(0, 6).map((news) => {
                 const { date } = formatDateTime(news.date);
                return (
                  <NavLink
                    key={news.id}
                    to={news.linkUrl}
                    className="no-underline text-white border-b-[1px] border-[#3e3d3c] "
                  >
                    Weekly Report  {date}
                  </NavLink>
                );
              })}
            </nav>
          </div>
        </div>
        <div>
          <img src={footer} alt="" className="h-[300px]" />
        </div>
      </div>
      <div className="h-auto bg-[#6D4828] border-t-[1px] flex justify-center items-center pt-[10px] px-[10px] sm:pt-[10px] font-poppins">
        <p className="text-white ">
          Kathmandu Gold and Silver Dealers' Association. 2013 - 2024 © All
          Rights Reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
