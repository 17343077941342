import React from 'react'
import WeeklyChart from '../../../components/homeComponents/WeeklyChart'
import GmsTola from '../../../components/homeComponents/GmsTola'
import GoToTop from '../../../Scroll/GoToTop'
import Chatbot from '../../../shared/ChatBot'

const Graph = () => {
  return (
    <div className='my-[20px]'>
      <GmsTola />
      <div className='mt-[20px]'>
      <WeeklyChart />
      </div>
     <GoToTop />
     <Chatbot />
    </div>
  )
}

export default Graph