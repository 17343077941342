import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminHeader from '../shared/AdminHeader';
import AdminSideBar from '../shared/AdminSideBar';
import { Container } from '../../../components/Container';

const AdminLayout = () => {
  return (
    <Container>
    <div className='flex h-screen'>
      {/* Sidebar */}
      <div className='w-64 fixed top-0 left-0 h-full bg-gray-800 text-white border-r-red-100 border-r-[1px]'>
        <AdminSideBar />
      </div>

      {/* Main content area */}
      <div className='flex-1 ml-64'>
        {/* Header */}
        <div className='w-full h-16 fixed top-0 left-64  '>
          <AdminHeader />
        </div>

        {/* Content */}
        <div className=' overflow-auto h-full'>
          <Outlet />
        </div>
      </div>
    </div>
    </Container>
  );
};

export default AdminLayout;

