import React from 'react'
import Form from '../../shared/Form'
import GmsTola from '../../components/homeComponents/GmsTola'
import GoToTop from '../../Scroll/GoToTop'
import Chatbot from '../../shared/ChatBot'

const ContactUs = () => {
  return (
    <div className='pt-[20px]'>
      <GoToTop />
      <GmsTola />
      <Chatbot />
      <Form title="Contact Us" />
    </div>
  )
}

export default ContactUs