import React, { useEffect, useState } from "react";
import GmsTola from "../../../components/homeComponents/GmsTola";

import ScrollTop from "../../../Scroll/ScrollTop";
import GoToTop from "../../../Scroll/GoToTop";
import Chatbot from "../../../shared/ChatBot";
import axios from "axios";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
const PastCommittee = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/v1/getpostcommittee`)
      .then((res) => {
        setData(res.data.data);
        console.log(res.data);
      })
      .catch((err) => { 
        console.log(err);
      });
  }, []);

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return {
      date: date.toLocaleDateString(),
    };
  };

  return (
    <div className="font-poppins ">
      <ScrollTop />
      <GmsTola />
      <Chatbot />
      <div className="mx-[10px]">
        <div className=" border-b-[1px] border-blue-gray-200 mt-[10px] mb-[40px] ">
          <h1 className="text-[35px] md:text-[25px] ">Past Committee</h1>
        </div>

        <div>
          {/* ---------Executive Committee members---- */}
          <div className="grid grid-cols-5 md:grid-cols-2 border-b-[2px] mb-[50px]">
            {data.map((member) => {
              const { date } = formatDateTime(member.date);
              return (
            
              <div
                key={member.id}
                className="flex items-center justify-center relative "
              >
                <div className="py-[10px]">
                  <img
                    className=" h-[150px] w-[150px] bg-red bottom-0"
                    src={member.imageUrl}
                    alt={member.name}
                  />

                  <div className="font-medium  text-center">
                    <p className="mb-[-4px]">{date}</p>
                    <p className="mb-[-4px]">{member.name}</p>
                    <p className="mb-[-4px]">{member.position}</p>
                  </div>
                </div>
              </div>
            )})}
          </div>
        </div>
      </div>
      <GoToTop />
    </div>
  );
};

export default PastCommittee;
